// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/dashboard/bg-txt.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".workers_s ul li[data-v-22f6ccb0] {\n  height: 35px;\n  line-height: 35px;\n  font-size: 14px;\n  padding-right: 30px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center bottom;\n  overflow: hidden;\n}\n.workers_s ul li span[data-v-22f6ccb0] {\n  display: block;\n  text-align: right;\n  float: right;\n  font-weight: 600;\n  color: #00fff1;\n  font-size: 16px;\n}\n.workers_s ul li .status1[data-v-22f6ccb0] {\n  width: 30px;\n  float: left;\n  margin-left: 40px;\n}\n.workers_s ul li .s_txt[data-v-22f6ccb0] {\n  margin-left: 110px;\n}\nul[data-v-22f6ccb0] {\n  margin: 0;\n  padding: 0;\n}\n", ""]);
// Exports
module.exports = exports;
