var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "workers_s" }, [
    _c(
      "ul",
      _vm._l(_vm.visilbeItems, function (item, index) {
        return _c("li", { key: index }, [
          _c("div", { staticClass: "status1" }, [
            _c("img", { attrs: { src: item.src, width: "100%" } }),
          ]),
          _c("span", [_vm._v(_vm._s(item.value))]),
          _c("div", { staticClass: "s_txt" }, [_vm._v(_vm._s(item.label))]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }